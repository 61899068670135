import axios, { AxiosRequestConfig } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import storageService from '../utils/storage';

const instance = axios.create({ baseURL: '/api' });

const requestInterceptor = (requestConfig: AxiosRequestConfig) => {
  if (requestConfig.url === '/user/login') {
    return requestConfig;
  }

  let token = storageService.getAccessToken();

  if (requestConfig.url === '/user/refresh') {
    token = storageService.getRefreshToken();
  }

  if (token && requestConfig.headers) {
    // eslint-disable-next-line no-param-reassign
    requestConfig.headers = Object.assign(requestConfig.headers, {
      Authorization: `Bearer ${token}`,
    });
  }

  return requestConfig;
};

instance.interceptors.request.use(requestInterceptor);

const request = applyCaseMiddleware(instance);

export default request;
