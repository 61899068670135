// register page
import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Select } from '@arco-design/web-react';
import styles from './Register.module.scss';

// 语文、数学、英语、美术、体育、科学、劳技、信息、品德与社会
const subjectOptions = [
  {
    label: '语文',
    value: 'chinese',
  },
  {
    label: '数学',
    value: 'math',
  },
  {
    label: '英语',
    value: 'english',
  },
  {
    label: '美术',
    value: 'art',
  },
  {
    label: '体育',
    value: 'physical',
  },
  {
    label: '科学',
    value: 'science',
  },
  {
    label: '劳技',
    value: 'labor',
  },
  {
    label: '信息',
    value: 'information',
  },
  {
    label: '品德与社会',
    value: 'moral',
  },
];

// 一（1）班~一（5）班  6个年级
const classOptions = [
  {
    label: '一（1）班',
    value: '1-1',
  },
  {
    label: '一（2）班',
    value: '1-2',
  },
  {
    label: '一（3）班',
    value: '1-3',
  },
  {
    label: '一（4）班',
    value: '1-4',
  },
  {
    label: '一（5）班',
    value: '1-5',
  },
];

const RegisterForm = () => {
  return (
    <Form
      initialValues={{
        remember: true,
      }}
      className={styles.form}
      autoComplete='off'
    >
      <Form.Item className={styles.title} wrapperCol={{ span: 24 }}>
        <span>用户注册</span>
      </Form.Item>
      <Form.Item
        label='用户名'
        field='username'
        rules={[
          {
            required: true,
            message: '请输入用户名',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='密码'
        field='password'
        rules={[
          {
            required: true,
            message: '请输入密码',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label='确认密码'
        field='confirmPassword'
        rules={[
          {
            required: true,
            message: '请再次输入密码',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label='姓名'
        field='name'
        rules={[
          {
            required: true,
            message: '请输入姓名',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='学校'
        field='school'
        rules={[
          {
            required: true,
            message: '请输入学校',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='班级'
        field='class'
        rules={[
          {
            required: true,
            message: '请输入班级',
          },
        ]}
      >
        <Select mode='multiple' options={classOptions}></Select>
      </Form.Item>
      <Form.Item
        label='任教科目'
        field='subject'
        rules={[
          {
            required: true,
            message: '请输入任教科目',
          },
        ]}
      >
        <Select mode='multiple' options={subjectOptions}></Select>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
        <Button type='primary' htmlType='submit' long>
          注册
        </Button>
      </Form.Item>
    </Form>
  );
};

const Register = () => {
  return (
    <div className={styles.page}>
      <RegisterForm />
    </div>
  );
};

export default Register;
