import { Slider, Space } from '@arco-design/web-react';
import { FC } from 'react';
import styles from './AnalysisLevel.module.scss';

type Props = {
  activeLevel?: number;
  focusLevel?: number;
};

const AnalysisLevel: FC<Props> = (props): JSX.Element => {
  const { activeLevel, focusLevel } = props;

  return (
    <div className={styles.component}>
      <Space>
        <img
          style={{ width: 22, verticalAlign: 'bottom' }}
          src='//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/48872c084d77494ebc0ddd0892d97e3d~tplv-uwbnlip3yd-image.image'
          alt='sad'
        />
        <Slider
          value={activeLevel ?? 0}
          max={4}
          marks={['沉闷', '较沉闷', '一般', '活跃', '非常活跃']}
          style={{ width: 400 }}
          disabled
        />
        <img
          style={{ width: 22, verticalAlign: 'bottom', marginLeft: 16 }}
          src='//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/40b91e0c13cb4069976ae726da62aa75~tplv-uwbnlip3yd-image.image'
          alt='small'
        />
      </Space>
      <Space className={styles.focus}>
        <img
          style={{ width: 22, verticalAlign: 'bottom' }}
          src='//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/48872c084d77494ebc0ddd0892d97e3d~tplv-uwbnlip3yd-image.image'
          alt='sad'
        />
        <Slider
          value={focusLevel ?? 0}
          max={4}
          marks={['分散', '较分散', '一般', '专注', '非常专注']}
          style={{ width: 400 }}
          disabled
        />
        <img
          style={{ width: 22, verticalAlign: 'bottom', marginLeft: 16 }}
          src='//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/40b91e0c13cb4069976ae726da62aa75~tplv-uwbnlip3yd-image.image'
          alt='small'
        />
      </Space>
    </div>
  );
};

export default AnalysisLevel;
