import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import { AuthContext } from './contexts/AuthContext';
import AppRouter from './router';

const queryClient = new QueryClient({});

function App() {
  const [isLogged, setIsLogged] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider
        value={{
          isLogged,
          setIsLogged,
        }}
      >
        <AppRouter />
      </AuthContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
