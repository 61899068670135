import { Button, Form, Input, Link, Message } from '@arco-design/web-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useLogin } from '../../../services/user';
import storageService from '../../../utils/storage';
import styles from './Login.module.scss';

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const loginMutation = useLogin();
  const authContext = useAuthContext();

  const handleSubmit = () => {
    form.validate().then((values) => {
      const { username, password } = values;
      loginMutation
        .mutateAsync({
          username,
          password,
        })
        .then((res) => {
          if (res.data.code === 1) {
            storageService.setAccessToken(res.data.data.accessToken);
            storageService.setRefreshToken(res.data.data.refreshToken);
            authContext.setIsLogged(true);
            Message.success('');
          } else {
            Message.error(res.data.msg);
          }
        });
    });
  };

  useEffect(() => {
    if (authContext.isLogged) {
      navigate('/dashboard');
    }
  }, [authContext.isLogged, navigate]);

  return (
    <div className={styles.page}>
      <Form form={form} className={styles.form} onSubmit={handleSubmit}>
        <Form.Item className={styles.title} wrapperCol={{ span: 24 }}>
          <span>欢迎使用</span>
        </Form.Item>
        <Form.Item field='username' label='用户名'>
          <Input></Input>
        </Form.Item>
        <Form.Item field='password' label='密码'>
          <Input.Password></Input.Password>
        </Form.Item>
        <Form.Item
          wrapperCol={{ offset: 5, span: 19 }}
          style={{ marginBottom: 8 }}
        >
          <Button htmlType='submit' type='primary' long>
            登录
          </Button>
        </Form.Item>
        <Form.Item style={{ textAlign: 'end' }} wrapperCol={{ span: 24 }}>
          <Link href='/register'>没有账号？点击注册</Link>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
