import styles from './AnalysisPerson.module.scss';

type Props = {
  mostActiveStudent?: string;
  highestEngagementStudent?: string;
  lowestEngagementStudent?: string;
};

const AnalysisPerson = (props: Props): JSX.Element => {
  const {
    mostActiveStudent,
    highestEngagementStudent,
    lowestEngagementStudent,
  } = props;
  return (
    <div className={styles.component}>
      <div className={styles.analysisGroup}>
        <div className={styles.analysisItem}>
          <span className={styles.analysisItemTitle}>最积极的学生</span>
          <span className={styles.analysisItemName}>
            {mostActiveStudent ?? '暂无'}
          </span>
        </div>
        <div className={styles.analysisItem}>
          <span className={styles.analysisItemTitle}>参与度最高的学生</span>
          <span className={styles.analysisItemName}>
            {highestEngagementStudent ?? '暂无'}
          </span>
        </div>
        <div className={styles.analysisItem}>
          <span className={styles.analysisItemTitle}>参与度最低的学生</span>
          <span className={styles.analysisItemName}>
            {lowestEngagementStudent ?? '暂无'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AnalysisPerson;
