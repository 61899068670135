import { Slider, Space } from '@arco-design/web-react';
import { FC } from 'react';
import { BEHAVIORS } from '../Dashboard';
import styles from './AnalysisCount.module.scss';

type Props = {
  data?: {
    behavior: string;
    count: number;
  }[];
};

const AnalysisCount: FC<Props> = (props): JSX.Element => {
  const { data } = props;

  return (
    <div className={styles.component}>
      {data?.map((item) => {
        const BEHAVIOR = BEHAVIORS[item.behavior];
        const hasCountColor = item.count ? '#fff' : '#999';

        return (
          <div
            className={styles.analysisItem}
            style={{
              color: hasCountColor,
            }}
          >
            <div
              className={styles.analysisItemType}
              style={{
                backgroundColor: hasCountColor,
              }}
            />
            <span
              className={styles.analysisItemTitle}
              style={{
                color: hasCountColor,
              }}
            >
              {BEHAVIOR.label}
            </span>
            <span
              className={styles.analysisItemValue}
              style={{
                color: hasCountColor,
              }}
            >
              {item.count}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default AnalysisCount;
