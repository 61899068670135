import { Button, Dropdown, Image, Menu, Space } from '@arco-design/web-react';
import {
  IconCalendar,
  IconCheckCircleFill,
  IconFile,
  IconFileVideo,
  IconMoreVertical,
} from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import { Video } from '../../../interface/video';
import { formatBytes } from '../../../utils/file';
import styles from './File.module.scss';

type Props = {
  video: Video;
  onSelect: (video: Video) => void;
  onAnalysis: (id: number) => void;
  onDelete: (id: number) => void;
};

const File = ({
  video,
  onSelect,
  onAnalysis,
  onDelete,
}: Props): JSX.Element => {
  return (
    <div className={styles.file}>
      <div className={styles.fileBody}>
        <Image
          className={styles.thumbnails}
          src={'/demo.jpg'}
          width={60}
          height={60}
          alt='thumbnail'
        />
        <div className={styles.fileInformation}>
          <div className={styles.fileInformationTop}>
            <IconFileVideo></IconFileVideo>
            <span
              className={styles.fileName}
              onClick={() => {
                onSelect(video);
              }}
              title={video.fileName}
            >
              {video.fileName}
            </span>
            {video.processedFilePath && (
              <IconCheckCircleFill className={styles.processedIcon} />
            )}
          </div>
          <div className={styles.fileMeta}>
            <Space size={12}>
              <Space className={styles.fileUploadedAt} size={4}>
                <IconFile></IconFile>
                <span>语文课</span>
              </Space>
              <Space className={styles.fileUploadedAt} size={4}>
                <IconCalendar></IconCalendar>
                <span>
                  {dayjs(video.uploadedAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>
              </Space>
            </Space>
          </div>
        </div>
        <Button.Group>
          <Button size='small' onClick={() => onAnalysis(video.id)}>
            分析
          </Button>
          <Dropdown
            droplist={
              <Menu>
                <Menu.Item
                  className={styles.danger}
                  key='delete'
                  onClick={() => onDelete(video.id)}
                >
                  删除
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              size='small'
              icon={<IconMoreVertical></IconMoreVertical>}
            ></Button>
          </Dropdown>
        </Button.Group>
      </div>
    </div>
  );
};

export default File;
