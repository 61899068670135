import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from '../features/Dashboard/Dashboard';
import Login from '../features/User/Login/Login';
import Register from '../features/User/Register/Register';

const router = createBrowserRouter([
  {
    path: '/register',
    element: <Register></Register>,
  },
  {
    path: '/',
    element: <Login></Login>,
  },
  {
    path: '/dashboard',
    element: <Dashboard></Dashboard>,
  },
]);

const AppRouter = () => {
  return (
    <Suspense fallback={<div>error!</div>}>
      <RouterProvider router={router}></RouterProvider>
    </Suspense>
  );
};

export default AppRouter;
