import {
  Button,
  Input,
  Upload,
  Checkbox,
  Message,
  Modal,
  Tooltip,
  Skeleton,
  Tabs,
  Space,
} from '@arco-design/web-react';
import { RequestOptions, UploadItem } from '@arco-design/web-react/es/Upload';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { Video } from '../../interface/video';
import {
  useAnalysisVideo,
  useDeleteVideo,
  useGetVideo,
  useGetVideoList,
  useUploadVideo,
} from '../../services/video';
import storageService from '../../utils/storage';
import AnalysisCount from './components/AnalysisCount';
import AnalysisLevel from './components/AnalysisLevel';
import AnalysisPerson from './components/AnalysisPerson';
import CustomChart from './components/Chart';
import File from './components/File';
import styles from './Dashboard.module.scss';
import svgUpload from '../../assets/images/undraw_upload_re_pasx.svg';
import svgNote from '../../assets/images/undraw_note.svg';
import svgSmileFace from '../../assets/images/undraw_smiley-face.svg';
import UploadVideoModal from './components/UploadVideoModal';
import { IconSwap, IconUpload } from '@arco-design/web-react/icon';
import { Chart, Legend, Line, LineAdvance } from 'bizcharts';
import WavesurferPlayer, { useWavesurfer } from '@wavesurfer/react';

export const BEHAVIORS: Record<string, { label: string; color: string }> = {
  sit: {
    label: 'C1端坐',
    color: '#ccc',
  },
  standUp: {
    label: 'C2站立',
    color: 'rgba(0, 221, 162, 1)',
  },
  handsUp: {
    label: 'C3举手',
    color: 'rgba(0, 221, 162, 1)',
  },
  read: {
    label: 'C4读书',
    color: 'rgba(0, 221, 162, 1)',
  },
  write: {
    label: 'C5写字',
    color: 'rgba(0, 221, 162, 1)',
  },
  lieOnTheDesk: {
    label: 'C6趴桌子',
    color: 'rgb(244, 83, 83)',
  },
  teach: {
    label: 'T1讲授',
    color: 'rgb(244, 83, 83)',
  },
  teacherWrite: {
    label: 'T2板书',
    color: 'rgb(244, 83, 83)',
  },
  teacherHandsUp: {
    label: 'T3教师举手',
    color: 'rgb(244, 83, 83)',
  },
  invite: {
    label: 'T4教师邀请',
    color: 'rgb(244, 83, 83)',
  },
};

const analysisOptions = [
  {
    label: 'C1端坐',
    value: 'sit',
  },
  {
    label: 'C2站立',
    value: 'standUp',
  },
  {
    label: 'C3举手',
    value: 'handsUp',
  },
  {
    label: 'C4读书',
    value: 'read',
  },
  {
    label: 'C5写字',
    value: 'write',
  },
  {
    label: 'C6趴桌子',
    value: 'lieOnTheDesk',
  },
  {
    label: 'T1讲授',
    value: 'teach',
  },
  {
    label: 'T2板书',
    value: 'teacherWrite',
  },
  {
    label: 'T3教师举手',
    value: 'teacherHandsUp',
  },
  {
    label: 'T4教师邀请',
    value: 'invite',
  },
];

const behaviorCounts = {
  sit: 0,
  standUp: 0,
  handsUp: 0,
  read: 0,
  write: 0,
  lieOnTheDesk: 0,
  teach: 0,
  teacherWrite: 0,
  teacherHandsUp: 0,
  invite: 0,
};

const mockData = [
  { date: '2022-02-13', score: 85, class: '语文' },
  { date: '2022-02-13', score: 90, class: '数学' },
  { date: '2022-02-13', score: 80, class: '英语' },
  { date: '2022-02-12', score: 75, class: '语文' },
  { date: '2022-02-12', score: 80, class: '数学' },
  { date: '2022-02-12', score: 85, class: '英语' },
  { date: '2022-02-11', score: 80, class: '语文' },
  { date: '2022-02-11', score: 75, class: '数学' },
  { date: '2022-02-11', score: 90, class: '英语' },
  { date: '2022-02-10', score: 90, class: '语文' },
  { date: '2022-02-10', score: 85, class: '数学' },
  { date: '2022-02-10', score: 70, class: '英语' },
  { date: '2022-02-09', score: 80, class: '语文' },
  { date: '2022-02-09', score: 80, class: '数学' },
  { date: '2022-02-09', score: 80, class: '英语' },
  { date: '2022-02-08', score: 75, class: '语文' },
  { date: '2022-02-08', score: 70, class: '数学' },
  { date: '2022-02-08', score: 75, class: '英语' },
];

const Dashboard = (): JSX.Element => {
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const [chartOptions, setChartOptions] = useState<string[]>();
  const [currentVideo, setCurrentVideo] = useState<Video>();
  const [file, setFile] = useState<UploadItem>();
  const [chartHeight, setChartHeight] = useState(0);
  const [activeTab, setActiveTab] = useState('video');
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const queryClient = useQueryClient();
  const getVideoListQuery = useGetVideoList();
  const deleteVideoMutation = useDeleteVideo();
  const analysisVideoMutation = useAnalysisVideo();
  const getVideoQuery = useGetVideo(currentVideo?.id);
  const [currentTime, setCurrentTime] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [wavesurfer, setWavesurfer] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const onReady = (ws: any) => {
    ws.setVolume(0);
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  // const {
  //   wavesurfer,
  //   isReady,
  //   isPlaying,
  //   currentTime: currentMp3Time,
  // } = useWavesurfer({
  //   container: containerRef,
  //   url: '/demo.mp3',
  //   waveColor: 'blue',
  //   height: 100,
  // });

  const videoList = getVideoListQuery.data?.data.list;

  const videoData = getVideoQuery.data?.data;

  const videoStatistics = videoData?.statistics;

  const handleDeleteVideo = (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确认删除该视频以及相关的分析数据吗？',
      onOk: () => {
        deleteVideoMutation
          .mutateAsync({ id: id })
          .then((res) => {
            if (res.data.code === 1) {
              Message.success('删除成功');
            } else {
              Message.error(res.data.msg);
            }
          })
          .finally(() => {
            queryClient.invalidateQueries(['GET_VIDEO_LIST']);
          });
      },
    });
  };

  const handleAnalysisVideo = (id: number) => {
    analysisVideoMutation.mutateAsync({ id: id }).then((res) => {
      if (res.data.code === 1) {
        Message.success('开始分析视频');
      }
    });
  };

  const getData = useMemo(() => {
    const allBehaviorCountsChart = videoData?.allBehaviorCountsChart;
    const finalData: any[] = [];
    chartOptions?.forEach((value) => {
      const name = analysisOptions.find((item) => item.value === value)?.label;
      allBehaviorCountsChart?.forEach((item) => {
        finalData.push({
          behavior: name,
          value: item[value] ?? 0,
          timestamp: item['timestamp'],
        });
      });
    });
    return finalData;
  }, [chartOptions, videoData?.allBehaviorCountsChart]);

  const filteredAnalysisOptions = useMemo(() => {
    const options = analysisOptions.map((item) => ({
      ...item,
      disabled: !Object.keys(videoData?.allBehaviorCounts ?? {}).includes(
        item.value
      ),
    }));

    const firstActiveOption = options.find((item) => !item.disabled);

    if (firstActiveOption) {
      setChartOptions([firstActiveOption?.value]);
    }

    return options;
  }, [videoData?.allBehaviorCounts]);

  const classroomStatistics = useMemo(
    () =>
      videoData?.allBehaviorCounts
        ? Object.keys(behaviorCounts).reduce(
            (prev, curr) => {
              prev.push({
                behavior: curr,
                count: videoData?.allBehaviorCounts[curr] ?? 0,
              });
              return prev;
            },
            [] as {
              behavior: string;
              count: number;
            }[]
          )
        : [],
    [videoData]
  );

  const handleLogout = () => {
    storageService.clearTokens();
    authContext.setIsLogged(false);
    navigate('/', { replace: true });
  };

  useLayoutEffect(() => {
    const height = chartContainerRef.current?.clientHeight;
    setChartHeight(height ?? 0);
  }, []);

  const teacherAnalysis = useMemo(() => {
    return videoData?.analysis
      ?.filter((item) => item.teacher === '教师')
      .sort((a, b) => a.endTime - b.endTime);
  }, [videoData?.analysis]);

  const studentAnalysis = useMemo(() => {
    return videoData?.analysis
      ?.filter((item) => item.teacher === '学生')
      .sort((a, b) => a.endTime - b.endTime);
  }, [videoData?.analysis]);

  const message = useMemo(() => {
    // 找到在结束时间之前，但是在
    const item = videoData?.analysis?.find((item, index) => {
      return (
        currentTime <= item.endTime &&
        currentTime >= videoData?.analysis[index - 1]?.endTime
      );
    });
    return item;
  }, [videoData?.analysis, currentTime]);

  const studentMessage = useMemo(() => {
    const item = studentAnalysis?.find((item, index) => {
      return (
        currentTime <= item.endTime &&
        currentTime >= studentAnalysis[index - 1]?.endTime
      );
    });
    return item;
  }, [studentAnalysis, currentTime]);

  const teacherMoodData = useMemo(() => {
    return teacherAnalysis?.map((item) => ({
      timestamp: item.endTime,
      value: item.mood,
    }));
  }, [teacherAnalysis]);

  const studentMoodData = useMemo(() => {
    return studentAnalysis?.map((item) => ({
      timestamp: item.endTime,
      value: item.mood,
    }));
  }, [studentAnalysis]);

  const teacherTextData = useMemo(() => {
    return teacherAnalysis?.map((item) => ({
      timestamp: item.endTime,
      value: item.lectureContent,
    }));
  }, [teacherAnalysis]);

  const studentTextData = useMemo(() => {
    return studentAnalysis?.map((item) => ({
      timestamp: item.endTime,
      value: item.lectureContent,
    }));
  }, [studentAnalysis]);

  const renderTextBlock = (text: string) => {
    // 如果为 A，渲染成圆形
    if (text === '提问类内容') {
      return <div className={styles.textBlockCircle}></div>;
    }
    // 如果为 B，渲染成椭圆形
    if (text === '评价指导类内容') {
      return <div className={styles.textBlockEllipse}></div>;
    }
    // 如果为 C，渲染成棱形
    if (text === '朗读类内容') {
      return <div className={styles.textBlockRhombus}></div>;
    }
    // 如果为 D，渲染成三角形
    if (text === '回答类内容') {
      return <div className={styles.textBlockTriangle}></div>;
    }
    // 如果为 E，渲染成正方形
    if (text === '讲授类内容') {
      return <div className={styles.textBlockSquare}></div>;
    }
    // 如果为 F，渲染成长方形
    if (text === '与学习无关类内容') {
      return <div className={styles.textBlockRectangle}></div>;
    }
  };

  return (
    <div className={styles.page}>
      <UploadVideoModal
        visible={uploadModalVisible}
        onCancel={() => setUploadModalVisible(false)}
      />
      <div className={styles.profile}>
        <Button type='text' onClick={handleLogout}>
          登出
        </Button>
      </div>
      <div className={styles.header}>师生互动分析</div>
      <div className={styles.center}>
        <div className={styles.centerLeft}>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <span className={styles.sectionHeaderTitle}>班级信息</span>
              <div className={styles.sectionHeaderRight}>
                <Button
                  icon={<IconSwap />}
                  size='mini'
                  type='text'
                  onClick={() => setUploadModalVisible(true)}
                >
                  切换班级
                </Button>
              </div>
            </div>
            <div className={styles.sectionBody}>
              <div className={styles.info}>
                <img src={svgNote} alt='info' className={styles.infoImg}></img>
                <span className={styles.classroomName}>
                  华南第八小学 三年一班
                </span>
              </div>
              <div className={styles.info}>
                <img
                  src={svgSmileFace}
                  alt='info'
                  className={styles.infoImg}
                ></img>
                <span className={styles.classroomNum}>32人</span>
                <span className={styles.classroomNum}>16男生</span>
                <span className={styles.classroomNum}>16女生</span>
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <span className={styles.sectionHeaderTitle}>视频列表</span>
              <div className={styles.sectionHeaderRight}>
                <Button
                  icon={<IconUpload />}
                  size='mini'
                  type='text'
                  onClick={() => setUploadModalVisible(true)}
                >
                  上传视频
                </Button>
              </div>
            </div>
            <div className={styles.sectionBody}>
              <div className={styles.fileList}>
                {getVideoListQuery.isLoading && <Skeleton></Skeleton>}
                {videoList?.map((video) => (
                  <File
                    video={video}
                    onSelect={setCurrentVideo}
                    onDelete={handleDeleteVideo}
                    onAnalysis={handleAnalysisVideo}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.centerMiddle}>
          {!currentVideo && (
            <div className={styles.uploadTipContainer}>
              <img
                className={styles.svgUpload}
                src={svgUpload}
                alt='Upload'
              ></img>
              <span className={styles.uploadTipText}>请上传并选择视频</span>
            </div>
          )}
          {currentVideo && (
            <video
              controls
              loop={false}
              muted={false}
              // poster={currentVideo.processedThumbnailPath}
              ref={videoRef}
              onPlay={() => {
                wavesurfer && wavesurfer.play();
                console.log('wavesurfer', wavesurfer);
              }}
              onPause={() => {
                wavesurfer && wavesurfer.pause();
              }}
              onTimeUpdate={(e) => {
                // 当前秒数
                const currentTime = e.currentTarget.currentTime;
                setCurrentTime(currentTime);
                console.log('ct', e.currentTarget.currentTime);
                if (wavesurfer) {
                  wavesurfer.seekTo(currentTime / e.currentTarget.duration);
                }
              }}
            >
              <source src={'/demo.mp4'} type='video/mp4' />
            </video>
          )}
        </div>
        <div className={styles.centerRight}>
          <Tabs type='rounded' activeTab={activeTab} onChange={setActiveTab}>
            <Tabs.TabPane key='video' title='视频分析'>
              <div className={styles.paneBody}>
                <div className={styles.section}>
                  <div className={styles.sectionHeader}>
                    <span className={styles.sectionHeaderTitle}>
                      课堂统计(次数)
                    </span>
                  </div>
                  <div className={styles.sectionBody}>
                    <AnalysisCount data={classroomStatistics}></AnalysisCount>
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionHeader}>
                    <span className={styles.sectionHeaderTitle}>课堂质量</span>
                  </div>
                  <div className={styles.sectionBody}>
                    <AnalysisLevel
                      activeLevel={videoStatistics?.activeLevel}
                      focusLevel={videoStatistics?.focusLevel}
                    />
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionHeader}>
                    <span className={styles.sectionHeaderTitle}>学生行为</span>
                  </div>
                  <div className={styles.sectionBody}>
                    <AnalysisPerson
                      mostActiveStudent={videoStatistics?.mostActiveStudent}
                      highestEngagementStudent={
                        videoStatistics?.highestEngagementStudent
                      }
                      lowestEngagementStudent={
                        videoStatistics?.lowestEngagementStudent
                      }
                    />
                  </div>
                </div>

                <div className={styles.sectionHeader}></div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane title='语音情绪检测' key='voice'>
              {/* <img
                src='/demo.png'
                alt='voice'
                style={{ width: '100%', height: '100%' }}
              ></img> */}
              <WavesurferPlayer
                height={100}
                waveColor='rgb(54, 115, 250)'
                url='/demo.mp3'
                onReady={onReady}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onSeeking={(e) => {
                  console.log('seeking', e.getCurrentTime());
                }}
              />
              <div
                style={{
                  marginTop: 30,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    marginRight: 16,
                  }}
                >
                  情绪角色与类别
                </span>
                <div
                  style={{
                    display: 'inline-flex',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <span className={styles.textSectionLabel}>
                    {message?.teacher}
                  </span>
                  <span className={styles.textSectionLabel}>
                    {message?.mood}
                  </span>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane title='话语文本分析' key='text'>
              <div className={styles.textSection}>
                <span className={styles.textSectionUser}>老师</span>
                <div className={styles.textSectionInput}>
                  {message?.teacher === '教师' ? message?.message : ''}
                </div>
                <span className={styles.textSectionLabel}>
                  {message?.teacher === '教师' ? message?.lectureContent : ''}
                </span>
              </div>
              <div className={styles.textSection}>
                <span className={styles.textSectionUser}>学生</span>
                <div className={styles.textSectionInput}>
                  {message?.teacher === '学生' ? message?.message : ''}
                </div>
                <span className={styles.textSectionLabel}>
                  {message?.teacher === '学生' ? message?.lectureContent : ''}
                </span>
              </div>
              <div className={styles.textButtonGroup}>
                <Button
                  size='large'
                  onClick={() => {
                    if (videoRef.current) {
                      videoRef.current.currentTime = 0;
                    }
                  }}
                >
                  上一句
                </Button>
                <Button size='large'>下一句</Button>
              </div>
            </Tabs.TabPane>
            {/* <Tabs.TabPane key='class' title='班级分析'>
              <Tabs>
                <Tabs.TabPane key='all' title='整体'>
                  <div className={styles.paneBody}>
                    <ul>
                      <li>英语课的得分较高，平均为 85 分，占总分的 35%。</li>
                      <li>
                        数学课和语文课的得分相对较低，分别平均为 70 分和 75
                        分，占总分的 30% 和 35%。
                      </li>
                      <li>
                        随着时间的推移，三个班级的课堂得分整体呈现下降趋势。
                      </li>
                    </ul>
                    <Chart
                      padding={[20, 20, 50, 40]}
                      autoFit
                      height={300}
                      data={mockData}
                    >
                      <LineAdvance
                        shape='smooth'
                        area
                        position='date*score'
                        color='class'
                      />
                      <Legend offsetY={10} />
                    </Chart>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane key='chinese' title='语文课'>
                  <div className={styles.paneBody}>123</div>
                </Tabs.TabPane>
                <Tabs.TabPane key='math' title='数学课'>
                  <div className={styles.paneBody}>123</div>
                </Tabs.TabPane>
                <Tabs.TabPane key='english' title='英语课'>
                  <div className={styles.paneBody}>123</div>
                </Tabs.TabPane>
              </Tabs>
            </Tabs.TabPane> */}
          </Tabs>
        </div>
      </div>
      <div className={`${styles.section} ${styles.bottom}`}>
        <div className={styles.sectionHeader}>
          <span className={styles.sectionHeaderTitle}>
            {activeTab === 'video' && '班级整体行为分析'}
            {activeTab === 'voice' && '师生语音情绪分析'}
            {activeTab === 'text' && '师生话语文本分析'}
          </span>
        </div>
        <div className={styles.sectionBody}>
          {activeTab === 'video' && (
            <div className={styles.analysisOptions}>
              <Checkbox.Group value={chartOptions} onChange={setChartOptions}>
                {filteredAnalysisOptions.map((item) => (
                  <Checkbox key={item.value} value={item.value}>
                    {({ checked }) => {
                      return (
                        <Tooltip
                          content={item.disabled ? '该视频中无此行为' : ''}
                        >
                          <Button
                            type={checked ? 'outline' : 'default'}
                            size='mini'
                            disabled={item.disabled}
                          >
                            {item.label}
                          </Button>
                        </Tooltip>
                      );
                    }}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div>
          )}
          <div className={styles.chart} ref={chartContainerRef}>
            {activeTab === 'video' && (
              <CustomChart data={getData} height={chartHeight} />
            )}
            {activeTab === 'voice' && (
              <>
                <div
                  style={{
                    height: chartHeight / 2 - 10,
                    display: 'flex',
                    gap: 20,
                    alignItems: 'center',
                  }}
                >
                  <span className={styles.textSectionLabel}>教师</span>
                  <Chart
                    padding={[10, 20, 50, 40]}
                    autoFit
                    height={chartHeight / 2 - 10}
                    data={teacherMoodData}
                    scale={{
                      value: {
                        type: 'cat',
                        values: ['消极', '中性', '积极'],
                        range: [0, 1],
                      },
                    }}
                  >
                    <Line shape='smooth' position='timestamp*value' />
                  </Chart>
                </div>
                <div
                  style={{
                    height: chartHeight / 2 - 10,
                    display: 'flex',
                    gap: 20,
                    alignItems: 'center',
                  }}
                >
                  <span className={styles.textSectionLabel}>学生</span>
                  <Chart
                    padding={[10, 20, 50, 40]}
                    autoFit
                    height={chartHeight / 2 - 10}
                    data={studentMoodData}
                    scale={{
                      value: {
                        type: 'cat',
                        values: ['消极', '中性', '积极'],
                        range: [0, 1],
                      },
                    }}
                  >
                    <Line shape='smooth' position='timestamp*value' />
                  </Chart>
                </div>
              </>
            )}
            {activeTab === 'text' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 12,
                    }}
                  >
                    {renderTextBlock('提问类内容')}
                    教师提问类内容
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 12,
                    }}
                  >
                    {renderTextBlock('评价指导类内容')}
                    教师评价指导类内容
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 12,
                    }}
                  >
                    {renderTextBlock('讲授类内容')}
                    教师讲授类内容
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 12,
                    }}
                  >
                    {renderTextBlock('朗读类内容')}
                    学生朗读类内容
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 12,
                    }}
                  >
                    {renderTextBlock('回答类内容')}
                    学生回答类内容
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 12,
                    }}
                  >
                    {renderTextBlock('与学习无关类内容')}
                    与学习无关类内容
                  </div>
                </div>
                <div
                  style={{
                    height: chartHeight / 2 - 10,
                    display: 'flex',
                    gap: 20,
                    alignItems: 'center',
                  }}
                >
                  <span className={styles.textSectionLabel}>教师</span>
                  <div
                    style={{
                      display: 'flex',
                      gap: 8,
                      alignItems: 'center',
                    }}
                  >
                    {teacherTextData?.map((item) =>
                      renderTextBlock(item.value)
                    )}
                  </div>
                </div>
                <div
                  style={{
                    height: chartHeight / 2 - 10,
                    display: 'flex',
                    gap: 20,
                    alignItems: 'center',
                  }}
                >
                  <span className={styles.textSectionLabel}>学生</span>
                  <div
                    style={{
                      display: 'flex',
                      gap: 8,
                      alignItems: 'center',
                    }}
                  >
                    {studentTextData?.map((item) =>
                      renderTextBlock(item.value)
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
