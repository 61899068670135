import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { BaseResponse } from '../interface/request';
import {
  deleteVideoParams,
  GetVideoListResult,
  UploadVideoParams,
  UploadVideoResult,
  Video,
} from '../interface/video';
import request from './request';

export const useGetVideoList = () => {
  return useQuery({
    queryKey: 'GET_VIDEO_LIST',
    queryFn: async () => {
      const res = await request.get<
        null,
        AxiosResponse<BaseResponse<GetVideoListResult>>
      >('/video');
      return res.data;
    },
  });
};

export const useGetVideo = (id?: number) => {
  return useQuery({
    queryKey: ['GET_VIDEO', id],
    queryFn: async () => {
      const res = await request.get<null, AxiosResponse<BaseResponse<Video>>>(
        `/video/${id}`
      );
      return res.data;
    },
    enabled: !!id,
  });
};

export const useUploadVideo = () => {
  return useMutation({
    mutationFn: async ({ file }: UploadVideoParams) => {
      const form = new FormData();
      form.append('file', file);
      console.log('f', file);
      const res = await request.post<
        UploadVideoParams,
        AxiosResponse<BaseResponse<UploadVideoResult>>
      >('/video', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res;
    },
  });
};

export const useAnalysisVideo = () => {
  return useMutation({
    mutationFn: async ({ id }: deleteVideoParams) => {
      const res = await request.patch<
        deleteVideoParams,
        AxiosResponse<BaseResponse<null>>
      >(`/video/${id}/analysis`);
      return res;
    },
  });
};

export const useDeleteVideo = () => {
  return useMutation({
    mutationFn: async ({ id }: deleteVideoParams) => {
      const res = await request.delete<
        deleteVideoParams,
        AxiosResponse<BaseResponse<null>>
      >(`/video/${id}`);
      return res;
    },
  });
};
