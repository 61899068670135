import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  Select,
  Upload,
} from '@arco-design/web-react';
import { RequestOptions } from '@arco-design/web-react/es/Upload';
import { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useUploadVideo } from '../../../services/video';
import styles from './UploadVideoModal.module.scss';

type Props = {
  visible: boolean;
  onCancel: () => void;
};

const UploadVideoModal: FC<Props> = (props): JSX.Element => {
  const { visible } = props;
  const { onCancel } = props;
  const queryClient = useQueryClient();
  const uploadVideoMutation = useUploadVideo();

  const handleUploadVideo = (options: RequestOptions) => {
    const { onError, onSuccess, file } = options;
    uploadVideoMutation
      .mutateAsync({
        file: file,
      })
      .then((res) => {
        if (res.data.code === 1) {
          Message.success('上传成功');
          onSuccess(res);
        } else {
          Message.error(res.data.msg);
          onError(res);
        }
      })
      .finally(() => {
        queryClient.invalidateQueries(['GET_VIDEO_LIST']);
      });
  };

  return (
    <Modal visible={visible} onCancel={onCancel} title='上传视频'>
      <Form>
        <Form.Item label='课堂类型'>
          <Select
            options={[
              {
                label: '语文课',
                value: 'chinese',
              },
              {
                label: '数学课',
                value: 'math',
              },
              {
                label: '英语课',
                value: 'english',
              },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item label='视频文件' className={styles.formItem}>
          <Upload
            customRequest={handleUploadVideo}
            showUploadList={false}
            accept='.mp4'
          >
            <div className={styles.uploadControl}>
              <Input className={styles.uploadInput} />
              <Button>选择文件</Button>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UploadVideoModal;
