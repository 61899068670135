import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { BaseResponse } from '../interface/request';
import { LoginByPasswordParams, LoginResult } from '../interface/user';
import request from './request';

export const useLogin = () => {
  return useMutation(async ({ username, password }: LoginByPasswordParams) => {
    const res = await request.post<
      LoginByPasswordParams,
      AxiosResponse<BaseResponse<LoginResult>>
    >('/user/login', { username, password });
    return res;
  });
};
