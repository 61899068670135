import { Chart, LineAdvance } from 'bizcharts';

type Props = {
  data: any[];
  height?: number;
};

const CustomChart = ({ data, height }: Props) => {
  return (
    <Chart padding={[10, 20, 50, 40]} autoFit height={height} data={data}>
      <LineAdvance
        shape='smooth'
        area
        position='timestamp*value'
        color='behavior'
      />
    </Chart>
  );
};

export default CustomChart;
