import React, { useContext } from 'react';

export const AuthContext = React.createContext<{
  isLogged: boolean;
  setIsLogged: (isLogged: boolean) => void;
}>({
  isLogged: false,
  setIsLogged: () => {},
});

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);
  return authContext;
};
